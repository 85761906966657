.App {
  text-align: center;
  /*height:100%;*/
  background-color:#000000;
  /*width:100%;*/
}

html {
  background: #000000; /* or any other color */
    -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family:archivoBlack;
  src:url(./fonts/ArchivoBlack-Regular.ttf)
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  /*--ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-right: env(safe-area-inset-right);
  --ion-safe-area-left: env(safe-area-inset-left);*/
}

/*@font-face {
  font-family:bevan;
  src:url(./fonts/Bevan-Regular.ttf)
}

@font-face {
  font-family:protestStrike;
  src:url(./fonts/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family:patuaOne;
  src:url(./fonts/PatuaOne-Regular.ttf)
}

@font-face {
  font-family:lackawannaWeed;
  src:url(./fonts/LackawannaWeedNf-jWgy.ttf)
}*/

.header-font {
  font-family:archivoBlack;
}

/*removes white border around app*/
/*html,*/
body {
  padding: 0;
  margin: 0; 
  /*height: 100%;*/
} 

p {
  font-family: 'Inter', sans-serif;
  /*color:#000000;*/
}

textarea {
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
}

/*------SKELETON---------*/
.main-container {
  display:flex;
  flex-direction:column;
  margin:0;
}

.header-container{
  /*height:60px;*/
  margin-top: env(safe-area-inset-top, 0px);
  height: 60px;
  /*background-color:#99d9ea;*/
  display:flex;
  flex-direction:row;
  width:100vw;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.3);
  z-index:1;
  align-items:center;
  width: calc(100vw - env(safe-area-inset-right, 0px) - env(safe-area-inset-left, 0px));
}
.header-container-compressed {
  height: 40px;
  flex-shrink:0;
}

.main-content {
  display:flex;
  flex-direction:row;
  height: calc(100vh - 60px - env(safe-area-inset-top, 0px) - env(safe-area-inset-bottom, 0px));
  width: calc(100vw - env(safe-area-inset-right, 0px) - env(safe-area-inset-left, 0px));
  margin-bottom: env(safe-area-inset-bottom, 0px);
}

.main-content-compressed {
  height: calc(100vh - 40px - env(safe-area-inset-top, 0px) - env(safe-area-inset-bottom, 0px));
}

.center-content {
  /*background-color: #424242;/*whitesmoke;pink;*/
  /*background-image: linear-gradient(to bottom right, #99d9ea, #f18202); */
  width:100%;
  overflow:auto;
  padding:15px 10px 30px 10px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
 /* Hide scrollbar for Chrome, Safari and Opera */
.review-list::-webkit-scrollbar {
  display: none;
}

.right-content {
  /*background-color: #363636;*/
  width:150px;
}

/*.left-content {
  background-color: #363636;
}*/
/*.left-content-shrunken {
  width:30px;
}*/

.popup-overlay {
  background-color: rgba(49,49,49,0.8);
  position:fixed;
  top: 0;
  bottom: 0;
  left:0;
  right:0;
  z-index:3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor:pointer;
}

.flex-row {
  display:flex;
  flex-direction:row;
  flex:1;
}

.hidden {
  display:none;
  height:initial;
  width:initial;
  position:initial;
  top:initial;
  bottom:initial;
  left:initial;
  right:initial;
  z-index:initial;
}

.dark-text {
  color:#000000;
}

/*------AUTHORIZATION---------*/

.signin-error {
  color: red;
  font-size:12px;
  margin-top:5px;
  height:12px;
}

.prompt-row{
  min-height:40px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content:center;
}


.prompt-row-compressed{
  display:flex;
  flex-direction:column;
}

.entry-prompt{
  margin-right:20px;
  text-align:right;
  font-size:16px;
  width:150px;
}

.entry-prompt-compressed{
  width:auto;
}

.signup-input {
  width:200px;

 /*   -webkit-transition: none;
    transition: none;*/
}

.signup-explain {
  font-size:12px;
  max-width:300px;
  margin-right:auto;
  margin-left:auto;
}

.loading-icon {
  height:20px;
  margin-right:5px;
}

.validate-username-button {
  margin-left:10px;
  font-size:12px;
  /*background-color:#568ea6;*/
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  margin-bottom:auto;
  color:#000000;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.username-buttons {
  flex-direction:row;
  display:flex;
}

h1 {
  font-size:35px;
  /*color:#292b29;*/
  font-family: 'Inter', sans-serif;
}

.signin-block {
  display: flex;
  flex-direction: column;
  /*background-color: #99d9ea;*/
  /*background-image: linear-gradient(to bottom right, #99d9ea, #f18202); */
  margin-right:auto;
  margin-left:auto;
  overflow:auto;
  border-radius:10px;
  padding:0px 30px 20px 30px;
  max-width:500px;
}

.signin-container {
  max-width:92%;
}

.signup-block {
  display: flex;
  flex-direction: column;
  margin-right:auto;
  margin-left:auto;
  overflow:auto;
  max-width:520px;
  padding:30px 30px 20px 30px;
  background:whitesmoke;
  border-radius:5px;
  align-items:center;
}


.login-to-signup {
  margin-top:10px;
  font-size:13px;
}

.login-header {
  font-size:30px;
  font-family:archivoBlack;
  margin-top:25px;
  margin-bottom:15px;
}


button {
  font-size:15px;
  padding:2px;
  border:none;
  cursor:pointer;
  color: #000;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.auth-button {
  margin-top:10px;
  /*margin-bottom:15px;*/
  margin-right:auto;
  margin-left:auto;
  /*background-color:#568ea6;*/
  padding:10px;
  border-radius:5px;
  color:#000000;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

/*------HEADER---------*/
.header-image{
  vertical-align: middle;
  height:45px;
  margin-left:10px;
  margin-right:45px;
}
.header-image-compressed {
  margin-left:0px;
  height:30px;
  display:flex;
  align-self:center;
}

.header-refresh {
  height:25px;
  cursor:pointer;
}

.menu-icon {
  height:20px;
  padding-left:10px;
  padding-right:20px;
  align-self:center;
  cursor:pointer;
}

.popup-menu {
  position:absolute;
  z-index:2;
  margin-left:0px;
  top:calc(40px + env(safe-area-inset-top));
  /*height: calc(100vh - 40px) -env(safe-area-inset-top) -env(safe-area-inset-bottom);*/
  bottom:calc(env(safe-area-inset-bottom));
  background-color: #202020;
  transition: width 2s;
}

.menu-popup-overlay {
  /*height:100vh;
  width:100vw;*/
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:1;
  display: flex;
}

.signed-in-container {
  display:flex;
  flex-direction: column;
  justify-content:right;
  min-width:150px;
  text-align:center;
  margin-right:15px;
  align-items:center;
}

.signed-in-container-compressed {
  flex-direction:row;
  flex:1;
}

.signed-in-username {
  font-size:20px;
  margin-top:auto;
  margin-bottom:auto;
  font-family:archivoBlack;
}

.signed-in-top-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.sign-out-button {
  height:18px;
  margin-bottom:0px;
}

.header-center-container {
  flex:1;
  text-align:center;
  overflow:hidden;
}

.send-to-login-button {
  margin:auto;
  /*background-color:#568ea6;*/
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  margin-right:0px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.login-button {
  margin:auto;
  /*background-color:#568ea6;*/
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  display:flex;
  flex-direction:row;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.link{
  text-decoration: none;
  color:#000000;
}

.light-link {
  color:whitesmoke;
  text-decoration:none;
}

.review-title-link {
  text-decoration:none;
  /*font-family:archivoBlack;*/
}

.unlock-icon-header {
  height:17px;
  margin-right:2px;
  cursor:pointer;
}

.unlock-icon-in-header {
  display:flex;
  flex-direction:row;
  align-items:center;
}

.unlocks-container-header {
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
  margin-bottom:5px;
  margin-top:3px;
  margin-left:20px;
  margin-right:20px;
  align-items:center;
  min-width:120px;
}

.unlocks-container-compressed {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding-right:20px;
  flex:1;
}

.header-token-count {
  /*margin-right:20px;*/
  cursor:pointer;
}

.header-title {
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items:center;
  height:100%;
  margin-left:84px;
}

.header-title-text{
  font-size:35px;
}

.header-star {
  height:23px;
  margin-left:3px;
  margin-right:3px;
}

.header-rating {
  font-size: 35px;
  margin-right:20px;
}

.header-sign-out-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
  margin-right:auto;
  margin-left:auto;
  /*background-color:#568ea6;*/
  border-radius:5px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.hidden-tag {
  color:whitesmoke;
  border-radius: 5px;
  padding: 5px;
  text-align:center;
  font-size:20px;
  margin-top:5px;
  margin-right:auto;
  margin-left:auto;
}

/*-------PERSONAL SUBJECTS FEED---------*/
.subject-tile {
  display: flex;
  flex-direction: column;
  /*background-color: #99d9ea;*/
  align-items: center;
  margin-bottom:20px;
  margin-left:auto;
  margin-right:auto;
  border-radius:5px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  max-width:700px;
}

.subject-tile-top-row {
  display: flex;
  flex-direction: row;
  width:100%;
  height: 40px;
}

.subject-tile-bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
  height: 40px;
}

.subject-tile-title {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left:20px;
  text-align:left;
  flex:1;
  text-decoration: none;
  color:#000000;
}

.reference-logo {
  height:20px;
  width:20px;
  margin-top:10px;
  margin-right:15px;
}

.subject-tile-date {
  /*background-color: #568ea6;*/
  font-size:13px;
  padding-left:10px;
  padding-right:10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius:8px;
  /*color: #292b29;*/
  height:15px;
  margin-top:auto;
  margin-bottom:auto;
  margin-left:20px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.subject-tile-enter-button {
  margin-right:15px;
  margin-top:7px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
  padding:5px;
  border-radius:5px;
  height:15px;
  font-size:13px;
  display:flex;
  flex-direction:row;
}

.subject-tile-entry-row {
  box-shadow: inset  0px 0px 2px rgba(0, 0, 0, 0.2);
  /*background-color: #568ea6;*/
  display: flex;
  flex-direction: column;
  padding:13px;
  box-sizing: border-box;
  border-radius:5px;
  overflow-y:auto;
  width:100%;
}

.subject-tile-entry-row-compressed {
  padding:8px;
}

.add-review-input {
  display:block;
  font-size:15px;
  margin-top:20px;
  margin-bottom:13px;
  background-color:whitesmoke;
  overflow-wrap:break-word;
  border-radius:5px;
  padding:5px;
}

.add-review-input-compressed {
  margin-top:8px;
  margin-bottom:5px;
}

.add-review-button {
  margin-left:20px;
  border-radius:5px;
  height:32px;
  padding:2px 8px 2px 8px;
  color:#000000;
  display:flex;
  flex-direction:row;
  align-items:center;
  text-align:center;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.button-row-submit {
  display:flex;
  flex-direction:row;
  justify-content:center;
}

.subject-tile-entry-stars {
  display:flex;
  flex-direction:row;
  /*height:30px;*/
  width:200px;
  padding:5px;
  border-radius:5px;
  height:38px;
  align-items:center;
  -webkit-tap-highlight-color: transparent;
}

.subject-tile-entry-stars-compressed {
  padding-left:10px;
  width:initial;
}

.stars-container {
  display:flex;
  flex-direction:row;
}
.star-container {
  position:relative;
  height:26px;
  cursor:pointer;
}

.subject-tile-entry-star {
  /*height:26px;*/
  width:29px;
}

.star-cover {
  position: absolute;
  left: 0px;
  top: 0px;
  width:100%;
  height:100%;
  display:flex;
  flex-direction:row;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  touch-action:none;
}

.star-half-cover {
  width:50%;
}

.stars-popup-overlay {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.unrated-review {
  filter: invert(27%) sepia(14%) saturate(0%) hue-rotate(148deg) brightness(89%) contrast(84%);
}

.subject-tile-entry-stars-not-submitted {
  border: 1px solid #000000;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  z-index:1;
}

.subject-tile-entry-star-compressed {
  width: 26px;
}

.review-star-counter {
  font-size: 30px;
  line-height:30px;
  width:42px;
  /*margin:auto;*/
  margin-left:auto;
  margin-right:auto;
  text-align:center;
}

.review-star-counter-compressed {
  font-size:20px;
  line-height:20px;
  width:initial;
  margin:initial;
  text-align:center;
  margin-left:2px;
}

.submitted-review {
  /*background-color:#99d9ea;*/
  /*background-image: linear-gradient(to bottom right, #99d9ea, #f18202); */
  border: 1px solid #000000;
  border-radius: 5px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  min-width:220px;
  padding-top:5px;
  padding-left:10px;
  padding-right:10px;
  padding-bottom:7px;
  word-wrap: break-word;
  display:flex;
  flex-direction:column;
}

.submitted-review-compressed {
  padding:3px;
}


.submitted-review-text {
  font-size:18px;
  border-radius:5px;
  text-align: left;
  padding-right: 10px;
  padding-left: 10px;
  line-height:1.4;
  margin-top:0px;
  margin-bottom:5px;
  font-family: 'Inter', sans-serif;
  white-space: pre-line;
}

.submitted-review-text-compressed {
  /*font-size:16px;*/
  padding-top:0px;
}

.unlock-prompt {
  padding-right:50px;
  padding-left:50px;
  padding-top:25px;
  padding-bottom:25px;
  /*background-color:#99d9ea;*/
  /*background-image: linear-gradient(to bottom right, #99d9ea, #f18202); */
  border-radius:5px;
  color:#000000;
}

.unlock-icon-popup {
  height:25px;
  margin-left:5px;
}

.unlock-icon-user-home {
  height:15px;
  display:inline;
}

.unlock-prompt-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
  width:40px;
  margin-right:auto;
  margin-left:auto;
  /*background-color:#568ea6;*/
  border-radius:5px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.submitted-time {
  font-size:12px;
  margin-bottom:0px;
  text-align:left;
  margin-left:10px;
}

.submitted-time-compressed {
  font-size:10px;
}

.home-feed {
  padding-right:20px;
  padding-left:20px;
}

.home-feed-compressed {
  padding-left: 0px;
  padding-right: 0px;
}

/*-------PUBLIC REVIEW FEED---------*/
.review-tile {
  /*background-color:#568ea6;
  background-color:#2e748c;
  
  /*----------------------------------------*/
  /*background-color:#363636;*/
  margin-bottom:15px;
  margin-left:auto;
  margin-right:auto;
  border-radius:5px;
  overflow: hidden;
  text-align:left;
  /*box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.2);*/
  padding-top:7px;
  padding-bottom:10px;
  padding-right:12px;
  padding-left:12px;
  box-sizing:border-box;
}

.review-tile-popup {
  width:500px;
  max-height:80%;
  overflow-y:auto;
  box-sizing:content-box;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  border-radius: 5px;
}

.review-tile-popup-compressed {
  width:95%;
  min-width:initial;
}

.button-row-popup {
  height:45px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.hidden-review {
  color: whitesmoke;
  margin-top:20px;
  margin-bottom:20px;
}

.review-list {
  padding-top:10px;
  overflow-y:scroll;
  padding-bottom:100px;
  box-sizing:border-box;
  width:100%;
  height:100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
 /* Hide scrollbar for Chrome, Safari and Opera */
 .review-list::-webkit-scrollbar {
  display: none;
}

.review-list-padding {
  padding-left:30px;
  padding-right:30px;
}

.following-list-spacer {
  height:10px;
}

.review-list-compressed {
  padding-top:10px;
  box-sizing:initial;
  width:initial;
  height:initial;
  overflow-y:initial;
}

.review-tile-title {
  text-align:left;
  /*color:#000000;*/
  color:whitesmoke;
  font-size: 20px;
  text-decoration: none;
  /*display: flex;
  align-items: center;*/
  display:inline;
  font-family:archivoBlack;
}

.submit-popup-title {
  text-align:center;
  font-size:20px;
  color:whitesmoke;
  font-family:archivoBlack;
  padding: 0px 10px 10px 10px;
}

.on-for-title {
  text-align:center;
  display:inline;
  color:whitesmoke;
}

.review-tile-title-container {
  display:flex;
  flex-direction:row;
  padding-bottom: 8px;
  padding-left:20px;
  align-items:center;
  justify-content:space-between;
}

.review-tile-title-container-compressed {
  padding-bottom:8px;
  padding-left:10px;
}

.review-tile-title-text {
  overflow-wrap:break-word;
  margin-top:0px;
  margin-bottom:0px;
}

.review-top-row {
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  /*padding-left:20px;*/
  /*flex-wrap:wrap;*/
}

/*.review-top-row-compressed{
  flex-direction:column;
}*/

.counter-group {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  /*transition: filter 200ms linear;*/

}
.like-group:hover {
  filter: invert(36%) sepia(75%) saturate(5772%) hue-rotate(84deg) brightness(93%) contrast(98%);
  cursor:pointer;
  
}
.liked-group {
  /*filter: invert(36%) sepia(75%) saturate(5772%) hue-rotate(84deg) brightness(93%) contrast(98%);*/
  filter: invert(36%) sepia(75%) saturate(5772%) hue-rotate(84deg) brightness(100%) contrast(100%);
}
.liked-group:hover{
  /*filter: invert(22%) sepia(22%) saturate(7087%) hue-rotate(79deg) brightness(97%) contrast(105%);*/
  filter: invert(22%) sepia(22%) saturate(7087%) hue-rotate(79deg) brightness(100%) contrast(100%);
}

.dislike-group:hover {
  filter: invert(15%) sepia(54%) saturate(7475%) hue-rotate(357deg) brightness(100%) contrast(100%);
  /*filter: invert(15%) sepia(54%) saturate(7475%) hue-rotate(357deg) brightness(94%) contrast(120%);*/
  cursor:pointer;
}
.disliked-group {
  filter: invert(15%) sepia(54%) saturate(7475%) hue-rotate(357deg) brightness(94%) contrast(120%);
  /*filter: invert(15%) sepia(54%) saturate(7475%) hue-rotate(357deg) brightness(94%) contrast(120%);*/
}
.disliked-group:hover{
  filter: invert(8%) sepia(96%) saturate(7480%) hue-rotate(6deg) brightness(100%) contrast(100%);
  /*filter: invert(8%) sepia(96%) saturate(7480%) hue-rotate(6deg) brightness(85%) contrast(109%);*/
}

.irrelevant-group {
  margin-left:15px;
}
.irrelevant-group:hover {
  filter: invert(49%) sepia(91%) saturate(2590%) hue-rotate(0deg) brightness(102%) contrast(105%);
  cursor:pointer;
}
.irrelevanted-group {
  filter: invert(49%) sepia(91%) saturate(2590%) hue-rotate(0deg) brightness(102%) contrast(105%);
}
.irrelevanted-group:hover{
  filter: invert(24%) sepia(45%) saturate(5948%) hue-rotate(35deg) brightness(98%) contrast(91%);
}

.like-dislike-icon {
  height:32px;
  /*margin-top:3px;*/
  margin-left:2px;
}

.like-dislike-icon-compressed {
  height:24px;
  /*margin-top:6px;*/
}

.comment-counter-group {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  /*transition: filter 200ms linear;*/
  margin-left:7px;
  opacity:1;
  cursor:pointer;
}

.comment-liked-group {
  filter: invert(67%) sepia(61%) saturate(2604%) hue-rotate(67deg) brightness(95%) contrast(100%);
}

.comment-disliked-group {
  /*filter: invert(33%) sepia(41%) saturate(6828%) hue-rotate(343deg) brightness(117%) contrast(115%);*/
  filter: invert(56%) sepia(34%) saturate(5946%) hue-rotate(326deg) brightness(125%) contrast(113%);
  /*filter: invert(53%) sepia(78%) saturate(3855%) hue-rotate(329deg) brightness(102%) contrast(113%);*/
}

.comment-vote-counter {
  color: #000000;
}

.comment-vote-icons {
  border-radius:3px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin: 3px 0px 3px 6px;
}

.irrelevant-icon {
  height: 28px;
  /*margin-top: 5px;*/
  margin-left:2px;
}

.irrelevant-icon-compressed {
  height: 20px;
  /*margin-top: 8px;*/
  margin-left:0px;
}

.vote-counter {
  font-size:23px;
  line-height:23px;
  padding-bottom:2px;
  margin-left:10px;
  margin-right:4px;
}

.vote-counter-compressed {
  font-size:20px;
  line-height:20px;
  margin-left:5px;
  margin-right:2px;
}

.vote-icons {
  margin-right:15px;
  display:flex;
  flex-direction:row;
  align-items:center;
  padding-top:3px;
}

.explain-container {
  position:relative;
}
.vote-icon-explain {
  position:absolute;
  background-color: #bfbfbf;
  border-radius:5px;
  padding:5px;
  font-size:12px;
  z-index:10;
  filter:none;
  text-align:center;
}

.like-explain {
  width:100px;
  top:-20px;
}

.dislike-explain {
  width:100px;
  top:-20px;
  left:80px;
}

.irrelevant-explain {
  width:150px;
  top:-35px;
  left:80px;
}

/*.title-follow-container {
  display:flex;
  flex-direction:row;
}*/
/*------homepage-------------------------*/
.homepage-wordart {
  width:100%;
}

/*-------confirm email page--------------*/
.logo-error {
  /*max-width: calc(100% - 500px);*/
  max-width: 350px;
  width:auto;
  margin-top: 25px;
  margin-left:40px;
  margin-right:40px;
  min-width:200px;
  /*filter: invert(6%) sepia(8%) saturate(3%) hue-rotate(317deg) brightness(94%) contrast(85%);*/
}

/*--------------------following------------------*/
.follow-button {
  color: #000000;
  font-size: 13px;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  margin-left:15px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
  font-family: 'Inter', sans-serif;
}

.follow-button-dark {
  margin-top:0px;
  margin-left:auto;
  margin-right:auto;
  font-size:15px;
  margin-left:20px;
  /*background: #99d9ea;*/
  box-shadow:initial;
  /*box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);*/
}
/*.follow-button-dark:hover {
  background-color: #;
}*/

.following-icon-title {
  width:19px;
  margin:4px 0px 4px 3px;
  vertical-align:middle;
  filter: invert(96%) sepia(10%) saturate(417%) hue-rotate(268deg) brightness(115%) contrast(92%);
  cursor:pointer;
}

.follow-icon-title {
  width:18px;
  padding:1px 0px 1px 1px;
  vertical-align:middle;
}

.unfollow-prompt {
  padding-right:50px;
  padding-left:50px;
  padding-top:25px;
  padding-bottom:25px;
  background:whitesmoke;
  border-radius:5px;
  color:#000000;
}

/*---------------navigation------------------*/
.navigation-container {
  margin-top:20px;
  margin-left:16px;
  display:flex;
  flex-direction:column;
}

.navigation-container-compressed {
  align-items:center;
  height:380px;
  justify-content:space-between;
}

.navigation-option {
  font-size:17px;
  margin-top:15px;
  display:flex;
  flex-direction:row;
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(145deg) brightness(109%) contrast(101%);
}

.navigation-option-compressed {
  font-size:26px;
  width:160px;
}

.subjects-icon {
  width:22px;
  padding:2px;
  margin-right:4px;
}

.following-icon {
  width:22px;
  margin-top:1px;
  padding:2px;
  margin-right:2px;
  margin-left:2px;
}

.navigation-with-terms {
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  height:100%;
  min-width:150px;
}

.navigation-terms-link {
  color:whitesmoke;
  font-size:12px;
  margin-bottom:50px;
}

.navigation-terms-container {
  margin-bottom:5px;
}

/*-----------------settings------------------*/
.settings-header {
 margin-top:40px;
 font-size:18px;
}

.settings-container {
  display: flex;
  flex-direction: column;
  margin-right:auto;
  margin-left:auto;
  margin-top:0px;
  overflow:auto;
  max-width:520px;
  padding:0px 30px 20px 30px;
  background-color:whitesmoke;
  border-radius: 5px;
}

.settings-prompt-row {
  margin-top:10px;
  margin-bottom:5px;
  display:flex;
  flex-direction:row;
  justify-content:center;
}

.settings-check-text {
  margin-left:10px;
  line-height:40px;
}

.settings-prompt-row-compressed {
  flex-direction:column;
  align-items:center;
}

.username-input {
  width:125px;
  height:16px;
  margin-bottom:10px;
}

.username-validation {
  padding:3px;
  padding-left:10px;
  font-size: 12px;
  height:18px;
}

.valid {
  color:green;
}

.invalid {
  color: red;
}

.change-password-button {
  margin-top:5px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:5px;
  font-size:12px;
  /*background-color:#568ea6;*/
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.terms-popup {
  max-width:80%;
  max-height:80%;
  margin-bottom:20px;
  overflow:scroll;
  border-radius:5px;
}

/*--------------subject/user summary ----------------*/

.summary-container {
  padding:20px;
  display:flex;
  flex-direction:column;
  max-width:270px;
  align-items:center;
}

.summary-container-compressed{
  max-width:none;
  padding:0px;
}

.summary-details-container {
  margin-bottom:15px;
  min-width:250px;
  max-width:700px;
  /*background-color:#99d9ea;*/
  background-color:#363636;
  padding:5px;
  border-radius:5px;
  /*box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.2);*/
  font-size:15px;
  color:whitesmoke;
}

.summary-details-container-compressed {
  width:100%;
  flex:1;
  box-sizing:border-box;
}

.username-container {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  min-width:220px;
  background-color:#363636;
  padding:10px 5px 10px 5px;
  border-radius:5px;
  /*box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.2);*/
  color:whitesmoke;
  font-size:18px;
  margin:0px 10px 15px 10px;
}
.username-container-compressed {
  width:100%;
  flex:1;
  box-sizing:border-box;
  margin-left:0px;
  margin-right:0px;
}

.title-summary-details {
  font-size:20px;
  padding-bottom:10px;
  padding-top:5px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  font-family:archivoBlack;
}

.summary-column {
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
}

.summary-column-spacer {
  height:8px;
}

.summary-info-compressed {
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
}

.summary-details-line {
  margin-top:3px;
}

.join-todays-conversation {
  padding:5px;
}

.rating-dist-container {
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  margin-bottom:15px;
  min-width:250px;
  /*background-color:#99d9ea;*/
  background-color:#363636;
  padding:5px;
  border-radius:5px;
  /*box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.2);*/
  font-size:13px;
  color:whitesmoke;
}

.rating-dist-container-compressed {
  width:100%;
  flex:1;
  margin-bottom:initial;
  min-width:150px;
}

.rating-bar {
  display:flex;
  flex-direction:row;
  align-items:center;
}

.rating-label {
  width:25px;
  /*border-right: solid #000000 2px;*/
}

.rating-rectangle {
  background-color:#f18202;/*#ff8900;*/
  border-radius:2px;
  margin:2px;
  /*border: #000000 1px solid;*/
  height:14px;
}

.rating-rectangle-selected {
  background-color: #93550d;
}

.center-content-subject {
  display:flex;
  flex-direction:row;
  /*overflow-y:auto;*/
  padding: 0px;
}

.distribution-label-star {
  height:10px;
  filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(180deg) brightness(116%) contrast(100%);
}

.bar-review-count {
  margin-left:5px;
  font-size:12px;
}

.subject-submission-status {
  /*background-color:#568ea6;*/
  background-color:whitesmoke;
  border-radius:5px;
  padding:5px;
  height:20px;
  line-height:20px;
  font-size:14px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.submit-todays-subject {
  text-align:center;
  font-size:30px;
  margin-top:40px;
  margin-right:auto;
  margin-left:auto;
}

.reference-logo-summary-table {
  margin-left:10px;
  height:20px;
  width:20px;
  filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(180deg) brightness(116%) contrast(100%);
  display:flex;
  align-self:center;
}

.map-logo-summary-table {
  height:20px;
  width:20px;
  margin-left:5px;
  /*filter: invert(18%) sepia(0%) saturate(1502%) hue-rotate(147deg) brightness(108%) contrast(93%);*/
}

.map-button {
  /*background-color:#568ea6;*/
  background-color:whitesmoke;
  border-radius:5px;
  padding:5px;
  padding-left:7px;
  display:flex;
  flex-direction:row;
  margin-right:10px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
  font-size:14px;
  line-height:20px;
}

.map-button-compressed {
  margin-right:0px;
}

.summary-row-reference {
  display:flex;
  flex-direction:row;
  justify-content:center;
  margin-top:8px;
  margin-bottom:5px;
  align-items:center;
}

.summary-row-reference-compressed {
  margin-top:0px;
}


/*-------------------subjects table-----------------------*/
.blue-row {
  /*background-color:#80bdd1;*/
  background-color:rgba(0,0,0,0.2)
}

.subject-table-container {
  display: flex;
  flex-direction:column;
  overflow:auto;
  /*background-color:#99d9ea;*/
  border-radius: 5px;
  align-items:center;
  margin-left:auto;
  margin-right:auto;
  max-width:700px;
  align-self:center;
}

.subject-table-container-compressed {
  /*margin-right:auto;*/
  margin-top:7px;
}

.subject-table-header-row {
  display:flex;
  flex-direction:row;
  /*background-color:#568ea6;*/
  width:100%;
  background-color:rgba(0,0,0,0.45);
}

.header-cell {
  padding:5px;
  text-align:center;
  font-size:16px;
  cursor:pointer;
}
.header-cell:hover {
  /*background-color:#044d67;*/
  color: whitesmoke;
}

.subject-table-row {
  display:flex;
  flex-direction:row;
  /*border-radius:5px;*/
  width:100%;
  min-height:27px;
  /*color:whitesmoke;*/
}

.subject-cell {
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
  font-size:14px;
  /*line-height:1;*/
  margin-top:0px;
  margin-bottom:0px;
  display:flex;
  justify-content:center;
  align-items:center;
}

.subject-col {
  width:25%;
  max-width:25%;
}
.subject-col-compressed {
  width:25%;
  font-size:14px;
  line-height:16px;
}

.date-col {
  width:25%;
}
.date-col-compressed {
  width:20%;
  font-size:14px;
  line-height:16px;
}

.avg-col {
  width:15%;
}
.avg-col-compressed {
  width:15%;
  font-size:14px;
  line-height:16px;
}

.count-col {
  width:15%;
}
.count-col-compressed {
  width:15%;
  font-size:14px;
  line-height:16px;
}

.stdev-col {
  width: 20%;
}
.stdev-col-compressed {
  width:25%;
  font-size:14px;
  line-height:16px;
}

.subject-table-star {
  height:12px;
  /*filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(180deg) brightness(116%) contrast(100%);*/
  margin-right:2px;
}

.distribution-star {
  height:12px;
  filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(180deg) brightness(116%) contrast(100%);
  margin-right:2px;
}

.center-content-subjects {
  /*background-color: #424242;/*whitesmoke;pink;*/
  /*background-image: linear-gradient(to bottom right, #99d9ea, #f18202);*/
  flex:1; /*fills width not used by right and left content*/
  width:100%;
  overflow:auto;
  /*padding:5px;*/
  padding-top:15px;
  padding-bottom:50px;
}

/*---------------------about page----------------------*/

.about-container {
  display: flex;
  flex-direction: column;
  margin-right:auto;
  margin-left:auto;
  overflow:auto;
  padding:40px 30px 20px 30px;
  /*background-color:#99d9ea;*/
  /*background-image: linear-gradient(to bottom right, #99d9ea, #f18202); */
  border-radius: 5px;
  max-width:700px;
}

.about-container-compressed {
  padding: 20px 15px 10px 15px;
}

.about-header {
  font-size:33px;
  line-height:50px;
  margin-bottom:20px;
  margin-top:15px;
  font-family:archivoBlack;
}

.about-subheader {
  font-size:23px;
  font-family:archivoBlack;
}

.about-text {
  font-size: 17px;
  line-height: 25px;
  list-style-type:square;
  text-align:left;
}

.about-summary-text {
  font-size: 17px;
  line-height:25px;
  text-align:center;
}

.about-example-logo {
  height:16px;
}

.about-like-dislike-logo {
  height:20px;
}

/*---------------------overall home page-------------------*/

.home-tiles {
  display:flex;
  flex-direction:row;
  align-items:center;
  width:100%;
}

.welcome {
  color:whitesmoke;
  font-size:20px;
  border-radius:5px;
  padding:10px;
  margin-right:auto;
  margin-left:auto;
  margin-bottom:10px;
  width:100%;
  box-sizing:border-box;
}

.welcome-compressed {
  width:100%;
  flex:1;
  box-sizing:border-box;
  max-width:524px;
}

.welcome-spacer {
  height:9px;
}

.yesterdays-subject-title {
  cursor:pointer;
  display:inline-block;
  text-decoration:none;
  color:whitesmoke;
}

@keyframes pulseSubjectTitle {
  0% {
    background-color:rgba(256, 256, 256, 0)
  }
  50% {
    background-color:rgba(256, 256, 256, 0.25)
  }
  100% {
    background-color:rgba(256, 256, 256, 0)
  }
}

.best-takes-title {
  color:whitesmoke;
  padding:10px;
  border-radius:5px;
  font-size:23px;
  margin-top:10px;
  margin-right:auto;
  margin-left:auto;
  margin-bottom:15px;
  max-width:500px;
  width:100%;
  box-sizing:border-box;
}

.home-tiles-left {
  padding:20px;
  display:flex;
  flex-direction:column;
  width:400px;
  align-items:center;
  overflow-y:scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.home-tiles-left-compressed {
  width:100%;
  padding:0px;
}

.random-subjects-table {
  /*background-color:#99d9ea;*/
  font-size:15px;
  border-radius:5px;
  /*box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.3);*/
  margin-right:auto;
  margin-left:auto;
  width:100%;
  max-width:524px;
}

.random-table-title {
  font-size:20px;
  padding:3px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  box-sizing:border-box;
}

.subject-col-random {
  width:60%;
}

.avg-col-random {
  width:20%;
}

.count-col-random {
  width:20%;
}

.cell-random {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height:18px;
  display:flex;
  justify-content:center;
  align-items:center;
}

.random-table-refresh {
  height:22px;
  margin-left: 10px;
}

/*-----------------sort container-----------------*/
.reviews-and-sort-container {
  flex:1;
  overflow:hidden;
  padding-right:10px;
  padding-left:10px;
}

.sort-and-dist {
  display:flex;
  flex-direction:row;
  width:100%;
  max-width:700px;
}

.sort-container {
  display:flex;
  flex-direction:row;
  border-radius:5px;
  /*background-color:#99d9ea;*/
  background-color:#303030;
  margin-top:20px;
  margin-right:auto;
  margin-left:auto;
  margin-bottom:10px;
  justify-content:space-between;
  /*box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.2);*/
  max-width:700px;
  overflow-x:hidden;
}

.sort-container-compressed {
  display:flex;
  flex-direction:column;
  margin-top:initial;
  margin-right:10px;
  flex:1;
  height:189px;
  margin-bottom:0px;
  max-width:115px;
}

.sort-with-date {
  display:flex;
  flex-direction:column;
  border-radius:5px;
  /*background-color: #363636;*/
  margin:20px;
  /*box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.2);*/
  align-self:start;
  min-width:220px;
  justify-content:space-between;
  padding:5px;
}

.sort-with-date-compressed {
  margin:0px;
  min-width:150px;
  justify-content:space-evenly;
  flex-direction:row;
}

.sort-with-date-col {
  display:flex;
  flex-direction:column;
  justify-content:center;
}

.sort-with-date-col-types {
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}

.sort-option {
  display:flex;
  flex-direction:row;
  padding:5px 7px 5px 7px;
  font-size:16px;
  line-height:16px;
  border-radius:5px;
  cursor:pointer;
  align-items:center;
  color:whitesmoke;
}
/*.sort-option:hover {
  background-color:#568ea6;
}*/
.sort-option-selected {
  /*background-color:#505050;*/
  background-color:rgba(256,256,256,0.25)
}
.sort-option-compressed {
  padding: 6px 5px 6px 5px;
  font-size:14px;
  line-height:14px;
}
.following-option {
  margin-top:30px;
  margin-bottom:30px;
}

.sort-icon {
  height:25px;
  padding-right:10px;
  filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(180deg) brightness(116%) contrast(100%);
}
.sort-icon-compressed {
  height:18px;
  padding-right:7px;
}



/*----------------------error page----------------------*/
.error-container {
  /*background-color:#99d9ea;*/
  /*background-color: #99d9ea;/*linear-gradient(to bottom right, #99d9ea, #f18202); */
  font-size:25px;
  max-width:700px;
  border-radius:5px;
  padding:10px;
  margin-right:auto;
  margin-left:auto;
  margin-top:15px;
  display:flex;
  flex-direction:column;
  align-items:center;
  /*color:#202020;*/
}


/*----------------------error page----------------------*/


.map-container {
  max-width:700px;
  flex:1;
}

.map {
  height:200px;
}

.map-legend {
  padding-left:50px;
  padding-right:50px;
  display:flex;
  flex-direction:row;
  color:white;
}

.color-bar {
  flex:1;
  margin:7px 30px 7px 30px;
  border: 1px solid #000000;
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.2);
}

.map-legend-label {
  font-size:35px;
  line-height:35px;
  margin-top:auto;
  margin-bottom:auto;
}

.map-back-button {
  /*background-color:#99d9ea;*/
  border-radius:5px;
  cursor:pointer;
  padding:5px 15px 5px 15px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
  margin-top:15px;
  margin-bottom:20px;
  display:inline-block;
}

.map-state-label {
  width:100px;
}

.map-distribution-container {
  margin-bottom:15px;
  padding:10px 10px 10px 5px;
  /*background-color:#99d9ea;*/
  border-radius:5px;
  /*box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.2);*/
  font-size:13px;
  color:whitesmoke;
  background-color:#363636;
}

.map-table-container {
  flex:1;
  max-width:350px;
  padding:20px;
}

.map-table-container-compressed {
  max-width:initial;
}

.map-rating-bar {
  display:flex;
  flex-direction:row;
  align-items:center;
  padding-top:2px;
  padding-bottom:2px;
}

/*------------------comment stuff--------------*/
.comment-button {
  cursor:pointer;
  float:left;
  color: #000000;
  font-size: 16px;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  margin-top:5px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
  font-family: 'Inter', sans-serif;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.comment-icon {
  width:23px;
}

.comment-input {
  padding:5px;
  flex:1;
  border-radius:5px;
  margin-right:10px;
  background-color:whitesmoke;
  overflow-wrap:break-word;
  width:200px;
}

.comment-input-row {
  display:flex;
  flex-direction:row;
  margin:10px 5px 5px 5px;
  align-items: flex-end; 
}

.comment {
  display:flex;
  flex-direction:column;
  padding:5px;
  padding-left:10px;
  text-align:left;
}

.comment-text {
  word-wrap: break-word;
}

.comment-compressed {
  padding-left:10px;
}

.hidden-comment {
  margin-top:20px;
  margin-bottom:20px;
  text-align:center;
}

.comment-info {
  display:flex;
  flex-direction:row;
  align-items:center;
  margin-bottom:2px;
}

.commenter-name {
  font-family: archivoBlack;
}

.commented-date {
  font-size:12px;
  padding-left:7px;
}

.comment-section {
  padding-top:5px;
  display:flex;
  flex-direction:column;
  color:#000000;
}

.light-text {
  color:whitesmoke;
}

.irrelevant-icon-comment {
  height:14px;
  margin-left:12px;
  cursor:pointer;
  margin-right:6px;
}

.like-dislike-icon-comment {
  height:15px;
  margin-left:5px;
}

.image-to-white {
  filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(180deg) brightness(116%) contrast(100%);
}

/*.irrelevant-icon-comment:hover {
  filter: invert(49%) sepia(91%) saturate(2590%) hue-rotate(0deg) brightness(102%) contrast(105%);
}*/

/*------------------------search page--------------------------*/

.search-tiles-container {
  padding:20px;
}
.search-tiles-container-compressed {
  padding:0px;
}

.search-container {
  display:flex;
  flex-direction:column;
  border-radius:5px;
  /*background-color:#99d9ea;*/
  padding:5px;
  /*margin-right:auto;
  margin-left:auto;*/
  margin-bottom:10px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2), inset  0px 0px 2px rgba(0, 0, 0, 0.2);
  width:300px;
}

.search-container-compressed {
  /*flex:1;*/
  /*width:initial;*/
  width:100%;
  box-sizing:border-box;
}

.search-row{
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
}

.search-results {
  margin-right:auto;
  margin-left:auto;
  padding-left:20px;
  padding-right:20px;
}

.search-input {
  flex:1;
  font-size:18px;
  /*border: 2px solid #568ea6;*/
  border-radius:5px;
}

.search-button {
  /*background-color:#568ea6;*/
  height:22px;
  margin-left:10px;
  border-radius:5px;
  cursor:pointer;
  padding:5px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 0 0 2px rgba(0, 0, 0, 0.3);
  border:1px #000000 solid;
}

.search-icon {
  height:18px;
  padding-right:3px;
}

.search-option {
  margin-top:5px;
  display:flex;
  flex-direction:row;
  padding:5px;
  font-size:18px;
  line-height:18px;
  border-radius:5px;
  cursor:pointer;
  align-items:center;
}

/*--------------------terms and conditions-------------------*/
.terms-and-conditions {
  background-color:whitesmoke;
  border-radius:5px;
  padding:20px;
}

.terms-top-title {
  font-size:33px;
}

.terms-title {
  font-size:26px;
  margin-top:20px;
  margin-bottom:20px;
}

.terms-subtitle {
  font-size:20px;
  margin-top:15px;
  margin-bottom:15px;
}

/*-----------------notifications-------------------*/

.notification-icon {
  height:20px;
  /*margin-right:20px;*/
  cursor:pointer;
}

.notification-container {
  background:whitesmoke;
  border-radius:5px;
  max-height:300px;
  overflow-y:scroll;
  max-width:90%;
  max-height:70%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5), inset  0px 0px 2px rgba(0, 0, 0, 0.4);
  position:absolute;
  top:calc(40px + env(safe-area-inset-top, 0px));
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
}

.notification-container-fullsize {
  right:20px;
  top:calc(60px + env(safe-area-inset-top, 0px));
  left:initial;
  margin-left:initial;
  margin-right:initial;
  max-width:300px;
}

.notification-row {
  padding:5px 20px 0px 20px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}

.notification-row-grey {
  background-color:#c7c7c7;
}

.notification-subrow {
  display:flex;
  flex-direction:row;
  padding-bottom:5px;
}

.notification-text {
  display:inline-block;
}

/*--------------GUESSING GAME-------------------*/
.guess-container {
  max-width:700px;
  border-radius:5px;
  padding:8px;
  margin-right:auto;
  margin-left:auto;
  margin-top:15px;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.guess-option {
  font-family:archivoBlack;
  font-size:35px;
  background-color: rgba(0,0,0,0.2);
  padding:10px;
  border-radius:5px;
  cursor:pointer;
}

.guess-or {
  font-size:25px;
  font-family:archivoBlack;
}

.guess-options {
  padding-top:15px;
  padding-bottom:15px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-evenly;
  width:100%;
}

/*-----------------------------------------*/
.splash-loading-icon {
  margin:auto;
  height:100px;
}